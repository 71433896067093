import { graphql, Link } from "gatsby";
import React from "react";
import { format } from "date-fns";
import Img from "gatsby-image";
import styled from "styled-components";
import SEO from "../components/SEO";
import CategoryList from "../components/CategoryList";
import Container from "../styles/Container";
import BlogItem from "../styles/BlogItem";
import PageHeading from "../styles/PageHeading";

export const query = graphql`
  query {
    posts: allSanityPost {
      nodes {
        id
        slug {
          current
        }
        title
        publishedAt
        mainImage {
          asset {
            fluid(maxHeight: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
        categories {
          id
          title
          colorHex
        }
        body
        excerpt
      }
    }
  }
`;

const BlogListing = styled.div`
  h2 {
    display: inline-block;
  }
`;

const Blog = ({ data }) => (
  <>
    <SEO title="📝 Stuff I've wrote" />
    <Container>
      <BlogListing>
        <PageHeading>Blog</PageHeading>
        {data.posts.nodes.map(post => (
          <BlogItem key={post.id}>
            <Link to={`/blog/${post.slug.current}`}>
              <Img fluid={post.mainImage?.asset?.fluid} />
            </Link>
            <div className="body">
              <div>
                <time>
                  {format(new Date(post.publishedAt), "MMMM do, yyyy")}
                </time>
                <Link to={`/blog/${post.slug.current}`}>
                  <h2>{post.title}</h2>
                </Link>
                <p>{post.excerpt}</p>
              </div>
              <CategoryList categories={post.categories} />
            </div>
          </BlogItem>
        ))}
      </BlogListing>
    </Container>
  </>
);

export default Blog;
