import styled from 'styled-components';

const BlogItem = styled.article`
  display: flex;
  margin: 2rem 0;

  .gatsby-image-wrapper {
    min-width: 200px;
    height: 145px;
    margin-right: 2rem;
  }

  .body {
    display: flex;
    min-height: 142px;
    flex-direction: column;
    justify-content: space-between;
    time {
      display: block;
      margin-top: -5px;
      font-family: var(--fontSecondary);
      font-size: 1.4rem;
      opacity: 0.7;
    }

    p {
      width: 100%;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    a {
      font-family: var(--fontSecondary);
      color: var(--black);
      h2 {
        margin: 0 0 0.5rem;
        font-size: 2rem;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  @media (max-width: 575.98px) {
    .gatsby-image-wrapper {
      min-width: 145px;
    }
    .body {
      a > h2 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      p {
        /* display: none; */
      }
    }
  }
`;

export default BlogItem;
